import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, Row, Col, Tab, Tabs, Nav, Accordion, Navbar } from 'react-bootstrap';
import featureBanner from './assets/images/Group 532933.png'
import featureBanner2 from './assets/images/a (1).png'
import playStore from './assets/images/play-store.svg'
import appStore from './assets/images/app-store.svg'
import doctorImg from './assets/images/12 3.png'
import doctorImg2 from './assets/images/38.png'
import patientImg from './assets/images/c 2.png'
import patientImg2 from './assets/images/Group 532934.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';

function Main() {
  const [key, setKey] = useState('home');
  const [key2, setKey2] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const featureSectionRef = useRef(null);
  const productSectionRef = useRef(null);
  const benifitSectionRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === "#product-section" && productSectionRef.current) {
      // Scroll to the feature section if the hash is present in the URL
      productSectionRef.current.scrollIntoView({ behavior: "smooth" });
      console.log("Scrolled to feature section");
    }

    if (window.location.hash === "#feature-section" && featureSectionRef.current) {
      // Scroll to the feature section if the hash is present in the URL
      featureSectionRef.current.scrollIntoView({ behavior: "smooth" });
      console.log("Scrolled to feature section");
    }

    if (window.location.hash === "#benifit-section" && benifitSectionRef.current) {
      // Scroll to the feature section if the hash is present in the URL
      benifitSectionRef.current.scrollIntoView({ behavior: "smooth" });
      console.log("Scrolled to feature section");
    }
  }, [window.location.hash]);
  
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []); 

  return (
    <>
      <Layout>
        {/* banner section */}
        <section className='banner-section'>
          <Container>
            <Row>
              <Col xl={6} lg={7} md={7} sm={12}>
              <div className='banner-content'>
  <div className='title-part'>
    <h6>Online Physiotherapy Platform | RecureMe</h6>
    <h1>Revolutionize Your Therapy & Exercise Journey</h1>
    <p>Transform the way you experience exercise and therapy. Our platform offers video workouts, seamless doctor-patient communication, and a path to a healthier you. Get started today!</p>
  </div>
  <div className='store-name'>
    <button  className='px-3  rounded'  style={{backgroundColor:"#ff4817",color:"white",paddingTop:"10px",paddingBottom:"10px"}}
 onClick={(e) => {
      e.preventDefault();
      window.open('https://physiotherapist.recureme.com', '_blank');
    }}>
      Start your 15-day free trial
    </button>
    {/* Uncomment and optimize this image similarly if needed */}
    {/* <button className='play-store'>
      <img src={playStore} alt='playStore' loading="lazy" />
    </button> */}
  </div>
</div>

              </Col>
            </Row>
          </Container>
        </section>
        {/* product section */}
        <section className='product-section'>
          <div className='container'>
          <div className='title-part'>
              <h6 className={`${key == 'profile' ? 'color-blue' : ''} `}>TRY RECUREME</h6>
              <h2>Track & Assign Home Workouts <br /> with RecureMe </h2>
            </div>
          <div className='bg-transparent border-0 shadow-lg' style={{"position":"relative","padding-bottom":"calc(56.16264294790343% + 41px)","height": "0","width": "100%"}}><iframe src="https://demo.arcade.software/Earsz5M02Cwc2lc5nfw4?embed&show_copy_link=true" title="RecureMe - Physiotherapist" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="clipboard-write" style={{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","color-scheme":"light"}}></iframe></div>

          </div>

        </section>
        <div className='product-section' id="product-section" ref={productSectionRef}>
          <div className='container'>
            <div className='title-part'>
              <h6 className={`${key == 'profile' ? 'color-blue' : ''} `}>PRODUCT</h6>
              <h2>Comprehensive Physiotherapy Platform</h2>
              <p>Unlock the full potential of physiotherapy with RecureMe, designed to enhance communication, therapy, and patient outcomes.</p>
            </div>
            <div className='tab-part'>
              <Tab.Container
                id="uncontrolled-tab-example"
                // defaultActiveKey="profile"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Row>
                  <Col sm={12}>
                    <Nav variant="tabs" className={`${key == 'profile' ? 'color-blue' : ''} `}>
                      <Nav.Item>
                        <Nav.Link eventKey="home">Doctor & Physiotherapist</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="profile">Patient</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="home">
                        <Row>
                          <Col lg={6} md={6} className='card-grid-data'>
                            <div className='product-card'>
                              <h3>Effortless Patient Appointment Management</h3>
                              <p>Our user-friendly interface simplifies daily patient appointment scheduling and management for doctors.</p>
                              <div className='image-part'>
                                <img src={doctorImg} alt='dotor' />
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={6} className='card-grid-data'>
                            <div className='product-card'>
                            <h3>Easy Collaborative Exercise Prescription</h3>
                            <p>Streamline exercise assignments with intuitive collaboration features between doctors and physiotherapists.</p>
                              <div className='image-part'>
                                <img src={doctorImg2} alt='dotor' />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="profile">
                        <Row>
                          <Col lg={6} md={6} className='card-grid-data'>
                            <div className='product-card light-theme'>
                              <h3>Find Top-Rated Doctors and Physiotherapists with Ease</h3>
                              <p>Locate the best healthcare professionals near you using our convenient doctor and physiotherapist search tool.</p>
                              <div className='image-part'>
                                <img src={patientImg} alt='dotor' />
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} md={6} className='card-grid-data'>
                            <div className='product-card light-theme'>
                              <h3>Access Ready-Made Exercises for Recovery</h3>
                              <p>Utilize our library of ready-to-perform exercises for optimal rehabilitation and recovery.</p>
                              <div className='image-part'>
                                <img src={patientImg2} alt='dotor' />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
        {/* feature-section */}
        <div className='feature-section light-theme-feature' id="feature-section" ref={featureSectionRef}>
          <div className='container'>
            <Row className='align-items-center feature-order-change'>
              <Col md={6} sm={12}>
                <div className='feature-content-part'>
                  <div className='title-part'>
                    <h6>FEATURES</h6>
                    <h2>Elevate Patient Care with Unified Communication</h2>
                    <p>Our platform empowers healthcare professionals to deliver exceptional care through unified communication and efficient profile management.</p>
                  </div>
                  <div className='feature-list-part'>
                    <ul>
                      <li>Unified Communication</li>
                      <li>Seamless Profiles</li>
                      <li>Efficient Management Tools</li>
                    </ul>
                  </div>
                  <div className='store-name'  >
                    <button className='app-store' onClick={(e) =>{
                  e.preventDefault();
                  window.open('https://apps.apple.com/us/app/recureme-physiotherapy/id6476655610', '_blank');
                }}>
                      <img src={appStore} alt='appStore' className='me-2' />
                    </button>
                    {/* <button className='play-store'>
                      <img src={playStore} alt='playStore' />
                    </button> */}
                  </div>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className='image-part'>
                  <img src={featureBanner2} alt='feature-banner-2' className='w-100' />
                </div>
              </Col>
            </Row>
          </div>
        </div>
         {/* feature-section */}
        <section className='feature-section'>
          <div className='container'>
            <Row className='align-items-center'>
              <Col md={6} sm={12}>
                <div className='image-part'>
                  <img src={featureBanner} alt='feature-banner' />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className='feature-content-part'>
                  <div className='title-part light-theme'>
                    <h6>FEATURES</h6>
                    <h2>Empower Patient Education with Expert Articles</h2>
                    <p>Expand your knowledge and em<p>Gain knowledge from articles authored by our expert doctors, designed to educate and inform patients for better healthcare decisions.</p>
power yourself with insightful articles published by our expert doctors, specifically designed to educate patients and promote informed healthcare decisions.</p>
                  </div>
                  <div className='feature-list-part'>
                    <ul>
                    <li>Unified Communication</li>
                      <li>Seamless Profile Management</li>
                      <li>Appointment Book</li>
                    </ul>
                  </div>
                  <div className='store-name'>
                    <button className='app-store' onClick={(e) =>{
                  e.preventDefault();
                  window.open('https://apps.apple.com/us/app/recureme-patient-wellness/id6476655418', '_blank');
                }}>
                      <img src={appStore} alt='appStore' className='me-2'  />
                    </button>
                    <button className='play-store' onClick={(e) =>{
                  e.preventDefault();
                  window.open('https://play.google.com/store/apps/details?id=com.recureme.patient&hl=en_IN&gl=US&pli=1', '_blank');
                }}>
                      <img src={playStore} alt='playStore' />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        {/* key-points-section  */}
        <section className='key-point-section' id="benifit-section" ref={benifitSectionRef}>
          <div className='container'>
            <div className='main-box'>
              <div className='title-part'>
                <h6>key points</h6>
                <h2>Streamlined Patient Management & Collaboration</h2>
                <p>Experience efficient patient management and seamless collaboration with our platform, designed to empower healthcare professionals and optimize care delivery.</p>
              </div>
              <div className='box-grid-part'>
                <Row>
                  <Col lg={4} md={4} sm={12} className='data-card-grid'>
                    <div className='custom-card'>
                      <div className='icon-part'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.28578 13.2857C5.70007 13.2857 6.85721 12.1285 6.85721 10.7143V8.99997C6.85721 8.52854 6.4715 8.14282 6.00007 8.14282H2.5715C2.10007 8.14282 1.71436 8.52854 1.71436 8.99997V10.7143C1.71436 12.1285 2.8715 13.2857 4.28578 13.2857Z" fill="black" />
                          <path d="M13.2429 16.3801L8.1 13.8086C7.98 13.7486 7.85143 13.7144 7.71429 13.7144H0.857143C0.385714 13.7144 0 14.1001 0 14.5715V23.1429C0 23.6144 0.385714 24.0001 0.857143 24.0001H7.71429C8.18571 24.0001 8.57143 23.6144 8.57143 23.1429V18.6172L12.9771 17.9915C13.3457 17.9401 13.6457 17.6486 13.7057 17.2801C13.7657 16.9115 13.5771 16.5429 13.2429 16.3801Z" fill="black" />
                          <path d="M19.7145 9.4285C21.1288 9.4285 22.2859 8.27136 22.2859 6.85707V5.14279C22.2859 4.67136 21.9002 4.28564 21.4288 4.28564H18.0002C17.5288 4.28564 17.1431 4.67136 17.1431 5.14279V6.85707C17.1431 8.27136 18.3002 9.4285 19.7145 9.4285Z" fill="black" />
                          <path d="M23.1428 9.85718H16.2857C16.1485 9.85718 16.02 9.89146 15.9 9.95146L10.7571 12.5229C10.4228 12.6943 10.2343 13.0543 10.2943 13.4229C10.3543 13.7915 10.6457 14.0829 11.0228 14.1343L15.4285 14.76V19.2857C15.4285 19.7572 15.8143 20.1429 16.2857 20.1429H23.1428C23.6143 20.1429 24 19.7572 24 19.2857V10.7143C24 10.2429 23.6143 9.85718 23.1428 9.85718Z" fill="black" />
                          <path d="M6.85714 6.85714H8.57143V7.71429C8.57143 8.04 8.75143 8.34 9.04286 8.47714C9.16286 8.54571 9.3 8.57143 9.42857 8.57143C9.60857 8.57143 9.78857 8.51143 9.94286 8.4L12 6.85714H13.7143C14.1857 6.85714 14.5714 6.47143 14.5714 6V0.857143C14.5714 0.385714 14.1857 0 13.7143 0H6.85714C6.38571 0 6 0.385714 6 0.857143V6C6 6.47143 6.38571 6.85714 6.85714 6.85714Z" fill="black" />
                        </svg>
                      </div>
                      <div className='card-content-part'>
                        <h4>Seamless Doctor-Patient Interaction</h4>
                        <p>Foster a collaborative approach to treatment and care with our app's direct and efficient communication channels.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12} className='data-card-grid'>
                    <div className='custom-card'>
                      <div className='icon-part'>
                        <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M24.3767 10.4H26V15.6H24.3767C24.0847 16.883 23.5759 18.1067 22.8724 19.2183L24.0033 20.3558L20.3347 24.0283L19.1983 22.8963C18.4372 23.3742 17.6239 23.7631 16.7742 24.0554V21.6667H17.8564V19.5C19.2192 18.4768 20.2259 17.0503 20.7338 15.4226C21.2418 13.7949 21.2252 12.0484 20.6865 10.4306C20.1478 8.81287 19.1143 7.40576 17.7323 6.40866C16.3503 5.41155 14.69 4.875 12.9865 4.875C11.283 4.875 9.62261 5.41155 8.24064 6.40866C6.85868 7.40576 5.82514 8.81287 5.28644 10.4306C4.74774 12.0484 4.73119 13.7949 5.23912 15.4226C5.74705 17.0503 6.75373 18.4768 8.11654 19.5V21.6667H9.19875V24.0554C8.34908 23.7631 7.53572 23.3742 6.77461 22.8963L5.63829 24.0283L1.96961 20.3558L3.10052 19.2183C2.40649 18.1049 1.90695 16.8813 1.62331 15.6H0V10.4H1.62331C1.91533 9.11697 2.42406 7.89327 3.12758 6.78167L1.99667 5.64417L5.66535 1.97167L6.80166 3.10375C7.90563 2.41182 9.11859 1.91184 10.3892 1.625V0H15.5838V1.625C16.8655 1.91733 18.0879 2.42659 19.1983 3.13083L20.3347 1.99875L24.0033 5.67125L22.8724 6.80875C23.5731 7.91206 24.0818 9.12637 24.3767 10.4ZM16.2331 10.8333C16.2331 10.1905 16.0427 9.56219 15.6859 9.02773C15.3292 8.49327 14.8221 8.07671 14.2289 7.83073C13.6357 7.58474 12.9829 7.52038 12.3531 7.64578C11.7233 7.77118 11.1448 8.08072 10.6908 8.53524C10.2367 8.98976 9.92751 9.56885 9.80224 10.1993C9.67696 10.8297 9.74126 11.4832 9.98699 12.0771C10.2327 12.6709 10.6488 13.1785 11.1827 13.5356C11.7167 13.8927 12.3444 14.0833 12.9865 14.0833C13.8475 14.0833 14.6733 13.7409 15.2822 13.1314C15.891 12.5219 16.2331 11.6953 16.2331 10.8333ZM9.19875 15.7083V20.5833H10.281V26H15.692V20.5833H16.7742V15.7083C16.7742 15.5647 16.7172 15.4269 16.6157 15.3253C16.5142 15.2237 16.3766 15.1667 16.2331 15.1667H9.73985C9.59634 15.1667 9.45871 15.2237 9.35724 15.3253C9.25576 15.4269 9.19875 15.5647 9.19875 15.7083Z" fill="black" />
                        </svg>
                      </div>
                      <div className='card-content-part'>
                        <h4>Comprehensive Patient Management</h4>
                        <p>Manage patient profiles, appointments, treatment plans, and progress tracking effortlessly, all in one centralized location.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12} className='data-card-grid'>
                    <div className='custom-card'>
                      <div className='icon-part'>
                        <svg width="24" height="24" viewBox="0 0 13 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.70043 18.8179C7.64306 20.3095 7.81518 20.8832 7.52832 21.8585L8.9626 20.7111L8.61837 24.8418C8.561 25.7598 9.24945 26.6204 10.2248 26.6777C10.2821 26.6777 10.3395 26.6777 10.3395 26.6777C11.2001 26.6777 12.0033 25.9893 12.0606 25.0713L12.4622 20.1374C12.2328 20.2522 12.0033 20.3095 11.7164 20.3095C10.9132 20.3669 8.38889 19.2195 7.70043 18.8179Z" fill="black" />
                          <path d="M12.6342 17.2114C12.6342 17.154 12.8637 15.8919 11.7162 15.3182C10.4541 14.6297 9.5935 15.7771 9.42139 15.8345C11.1425 16.7524 12.2899 16.9245 12.6342 17.2114Z" fill="black" />
                          <path d="M3.68446 6.65506C5.52221 6.65506 7.01199 5.16527 7.01199 3.32753C7.01199 1.48979 5.52221 0 3.68446 0C1.84672 0 0.356934 1.48979 0.356934 3.32753C0.356934 5.16527 1.84672 6.65506 3.68446 6.65506Z" fill="black" />
                          <path d="M1.90574 10.7858C2.02048 9.92521 2.76631 9.17939 3.79899 9.29413C4.71693 9.40887 5.40538 10.2694 5.29064 11.1874C5.29064 11.1874 5.23327 12.679 7.12652 14.3428V10.9005C7.12652 9.00727 5.57749 7.45825 3.68424 7.45825C1.791 7.45825 0.241974 9.00727 0.241974 10.9005V20.6536C0.241974 21.17 0.356716 21.6863 0.586201 22.1453L0.815686 27.0792L0.0124888 34.8243C-0.102254 35.7422 0.586201 36.6028 1.56151 36.7176C2.47945 36.8323 3.34002 36.1438 3.45476 35.1685C4.3727 25.9891 4.3727 27.9398 4.14321 24.0385C5.86435 23.809 7.12652 22.3748 7.12652 20.6536V18.5309C4.25796 16.8671 1.56151 13.7691 1.90574 10.7858Z" fill="black" />
                          <path d="M12.0607 17.5556C4.54504 15.0313 4.71715 11.3022 4.71715 11.0727C4.77452 10.4416 4.31555 9.86788 3.68447 9.81051C3.05339 9.75313 2.47968 10.2121 2.42231 10.8432C2.42231 11.0727 1.8486 16.5803 11.2575 19.7357C12.7491 20.1947 13.4949 18.072 12.0607 17.5556Z" fill="black" />
                        </svg>
                      </div>
                      <div className='card-content-part'>
                        <h4>Personalized Exercise Prescription</h4>
                        <p>Tailor exercise plans to individual needs with our comprehensive tools, empowering personalized care.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12} className='data-card-grid'>
                    <div className='custom-card'>
                      <div className='icon-part'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 0C7.73942 0 4.28564 3.45381 4.28564 7.71429C4.28564 11.9748 7.73942 15.4286 11.9999 15.4286C16.2604 15.4286 19.7142 11.9748 19.7142 7.71429C19.7142 3.45381 16.2604 0 11.9999 0ZM11.9999 3C12.4733 3 12.8571 3.38376 12.8571 3.85714V7.30234L14.6782 8.75923C15.0479 9.05494 15.1078 9.59434 14.8121 9.96403C14.5164 10.3337 13.977 10.3936 13.6073 10.0979L11.4645 8.38363C11.2612 8.22094 11.1428 7.97469 11.1428 7.71429V3.85714C11.1428 3.38376 11.5265 3 11.9999 3Z" fill="black" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.42857 17.1428C1.53502 17.1428 0 18.6779 0 20.5714C0 22.4649 1.53502 24 3.42857 24H20.5714C22.4649 24 24 22.4649 24 20.5714C24 18.6779 22.4649 17.1428 20.5714 17.1428H3.42857ZM2.57143 20.5714C2.57143 20.098 2.95519 19.7143 3.42857 19.7143H8.57143C9.04483 19.7143 9.42857 20.098 9.42857 20.5714C9.42857 21.0448 9.04483 21.4285 8.57143 21.4285H3.42857C2.95519 21.4285 2.57143 21.0448 2.57143 20.5714Z" fill="black" />
                        </svg>
                      </div>
                      <div className='card-content-part'>
                        <h4>Real-Time Progress Monitoring</h4>
                        <p>Gain real-time insights into patient progress, enabling proactive adjustments and informed decision-making.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12} className='data-card-grid'>
                    <div className='custom-card'>
                      <div className='icon-part'>
                        <svg width="24" height="24" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.9115 10.5869V12.7991C21.7008 12.641 21.5955 12.5884 21.5955 12.5357C21.2794 12.2724 20.9107 12.1143 20.4894 12.1143C20.068 12.1143 19.6993 12.2724 19.3833 12.5357C19.3833 12.5357 18.3298 13.4838 16.381 13.4838C16.3283 13.4838 15.6963 13.3784 15.1169 13.9578C14.8009 14.2739 14.6429 14.6426 14.6429 15.0639V18.2242C14.6429 18.8562 14.6955 19.4356 14.8536 20.015H2.37049C1.63309 20.015 1.05371 19.4356 1.05371 18.6982V8.00599C1.05371 7.26859 1.63309 6.68921 2.37049 6.68921H7.11089C7.69028 6.68921 8.21699 7.05791 8.375 7.63729C8.53301 8.11133 8.84904 8.5327 9.21774 8.84873C9.63911 9.16475 10.1131 9.32277 10.6399 9.32277H20.6474C21.3321 9.27009 21.9115 9.84948 21.9115 10.5869Z" fill="black" />
                          <path d="M1.84349 2.52832V5.68859C0.790067 5.89927 0 6.84735 0 8.00612V4.68784C0 3.52907 0.842739 2.68633 1.84349 2.52832Z" fill="black" />
                          <path d="M22.9646 4.63517V10.5343C22.9646 9.37557 22.1745 8.48016 21.1211 8.21681V2.52832C22.1218 2.68633 22.9646 3.52907 22.9646 4.63517Z" fill="black" />
                          <path d="M20.0678 1.42212V8.2167H10.6396C10.3763 8.2167 10.1129 8.11136 9.84957 7.95335C9.63888 7.79533 9.48087 7.53198 9.37552 7.32129C9.0595 6.32054 8.16409 5.63581 7.11066 5.63581H2.89697V1.42212C2.89697 0.632054 3.52903 0 4.31909 0H18.6456C19.4357 0 20.0678 0.632054 20.0678 1.42212Z" fill="black" />
                          <path d="M25.282 15.0114V18.1717C25.282 20.9632 23.5438 23.4915 20.9629 24.4922C20.6996 24.5975 20.6469 24.6502 20.4889 24.6502C20.3309 24.6502 20.2782 24.5975 20.0148 24.4922C17.4339 23.4915 15.6958 20.9632 15.6958 18.1717V15.0114C15.6958 14.8534 15.7485 14.748 15.8538 14.6427C15.9592 14.5374 16.1172 14.4847 16.2225 14.4847C18.7507 14.5374 20.0675 13.3259 20.1202 13.2732C20.3309 13.0626 20.6469 13.0626 20.8576 13.2732C20.9102 13.3259 22.2797 14.5374 24.7552 14.4847C24.9133 14.4847 25.0186 14.5374 25.1239 14.6427C25.2293 14.748 25.282 14.9061 25.282 15.0114Z" fill="black" />
                        </svg>
                      </div>
                      <div className='card-content-part'>
                        <h4>Secure Data and Privacy</h4>
                        <p>Ensure patient confidentiality and regulatory compliance with our robust security measures and unwavering commitment to data privacy.</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12} className='data-card-grid'>
                    <div className='custom-card'>
                      <div className='icon-part'>
                        <svg width="24" height="24" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M27.9634 18.6423H26.9277V4.14273H27.9634C28.533 4.14273 28.9991 3.67667 28.9991 3.10704C28.9991 2.53742 28.533 2.07136 27.9634 2.07136H15.5352V1.03568C15.5352 0.466057 15.0692 0 14.4995 0C13.9299 0 13.4639 0.466057 13.4639 1.03568V2.07136H1.03568C0.466057 2.07136 0 2.53742 0 3.10704C0 3.67667 0.466057 4.14273 1.03568 4.14273H2.07136V18.6423H1.03568C0.466057 18.6423 0 19.1083 0 19.6779C0 20.2476 0.466057 20.7136 1.03568 20.7136H12.6664L10.5018 24.3282C10.2118 24.8149 10.3672 25.4467 10.8643 25.747C11.3511 26.037 11.9828 25.8817 12.2832 25.3846L14.4995 21.6872L16.7159 25.3846C16.9127 25.716 17.2545 25.892 17.6066 25.892C17.793 25.892 17.9691 25.8403 18.1348 25.747C18.6319 25.4467 18.7873 24.8149 18.4973 24.3282L16.3327 20.7136H27.9634C28.533 20.7136 28.9991 20.2476 28.9991 19.6779C28.9991 19.1083 28.533 18.6423 27.9634 18.6423ZM10.3568 14.4995C10.3568 15.0692 9.89076 15.5352 9.32113 15.5352C8.75151 15.5352 8.28545 15.0692 8.28545 14.4995V12.4282C8.28545 11.8586 8.75151 11.3925 9.32113 11.3925C9.89076 11.3925 10.3568 11.8586 10.3568 12.4282V14.4995ZM15.5352 14.4995C15.5352 15.0692 15.0692 15.5352 14.4995 15.5352C13.9299 15.5352 13.4639 15.0692 13.4639 14.4995V7.24977C13.4639 6.68015 13.9299 6.21409 14.4995 6.21409C15.0692 6.21409 15.5352 6.68015 15.5352 7.24977V14.4995ZM20.7136 14.4995C20.7136 15.0692 20.2476 15.5352 19.6779 15.5352C19.1083 15.5352 18.6423 15.0692 18.6423 14.4995V10.3568C18.6423 9.78719 19.1083 9.32113 19.6779 9.32113C20.2476 9.32113 20.7136 9.78719 20.7136 10.3568V14.4995Z" fill="black" />
                        </svg>
                      </div>
                      <div className='card-content-part'>
                        <h4>Empowering Insights and Analytics</h4>
                        <p>Leverage valuable data-driven insights into patient outcomes and treatment effectiveness to drive continuous improvement and evidence-based decision-making.</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>
        {/* faq section */}
        <section className={`${key2 == 'profile' ? 'color-blue' : ''} faq-section`}>
          <div className='container'>
            <div className='title-part'>
              <h6 className={`${key2 == 'profile' ? 'color-blue' : ''} `}>FAQS</h6>
              <h2>Frequently asked questions</h2>
            </div>
            <div className='tab-part'>
              <Tab.Container
                id="uncontrolled-tab-example"
                // defaultActiveKey="profile"
                className="mb-3"
                activeKey={key2}
                onSelect={(k) => setKey2(k)}
              >
                <Row>
                  <Col sm={12}>
                    <Nav variant="tabs" >
                      <Nav.Item>
                        <Nav.Link eventKey="home">Doctor & Physiotherapist</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="profile">Patient</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="home">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>What is RecureMe?</Accordion.Header>
                            <Accordion.Body>
                              RecureMe is an exercise program software specifically designed for rehabilitation professionals. It offers a wide range of exercises and is focused on enhancing patient engagement and recovery.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>How can I use RecureMe in my practice?</Accordion.Header>
                            <Accordion.Body>
                              RecureMe can be integrated into your practice to provide tailored exercise programs for your patients. It simplifies the process of creating and managing these programs.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="2">
                            <Accordion.Header>What are the benefits of using RecureMe?</Accordion.Header>
                            <Accordion.Body>
                              RecureMe simplifies program building and customization, enhances patient engagement, and allows for effective monitoring of patient adherence and progress. It offers a variety of exercises and templates suitable for different rehabilitation requirements.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>Can I customize exercise programs for my patients?</Accordion.Header>
                            <Accordion.Body>
                              Yes, RecureMe enables you to customize exercise programs quickly and efficiently. You can create programs from scratch or use pre-made templates, and adapt them to each patient’s specific needs.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>Is there support and training available for using RecureMe?</Accordion.Header>
                            <Accordion.Body>
                              Yes, RecureMe offers customer support and resources to assist healthcare professionals in effectively using the software. This includes guidance on program building and customization.
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Tab.Pane>
                      <Tab.Pane eventKey="profile">
                        <Accordion defaultActiveKey="0" className='profile-accordion'>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>What is RecureMe?</Accordion.Header>
                            <Accordion.Body>
                              RecureMe is a home exercise program that provides patients with a platform for rehabilitation exercises. It includes a comprehensive library of exercises with informative videos and step-by-step instructions.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>How can I access my exercise program?</Accordion.Header>
                            <Accordion.Body>
                              Your personalized exercise program is accessible online through RecureMe’s platform. You can log in to view and follow your exercise routine.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="2">
                            <Accordion.Header>What types of exercises are available?</Accordion.Header>
                            <Accordion.Body>
                              RecureMe offers a diverse range of exercises covering multiple rehabilitation specialties. These exercises are designed to cater to various rehabilitation needs.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>Can I track my progress?</Accordion.Header>
                            <Accordion.Body>
                              Yes, RecureMe allows you to monitor your progress and adherence to the exercise routine. This feature helps you stay on track with your rehabilitation goals.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>Is there support available if I have questions?</Accordion.Header>
                            <Accordion.Body>
                              Yes, if you have questions or need assistance, you can contact RecureMe’s customer support for help.
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </section>
        </Layout>
    </>

  );
}

export default Main;
