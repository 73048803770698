import React, { useEffect, useRef, useState } from "react";
import "./communityCard.css";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import DoctorDummy from "./assets/images/defaultdoctor@3x.png";
import Layout from "./Layout";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
const CommunityCardDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [communityData, setCommunityData] = useState({});
  const [communitiesList, setcommunitiesList] = useState({
    items: [],
    has_more: false,
  });
  useEffect(() => {
    if (location?.state?.comunity) {
      setCommunityData(location?.state?.comunity);
    }
    if (location?.state?.allCommunities) {
      // Filter out the current community using its _id
      const otherCommunities = location.state.allCommunities.filter(
        (item) => item?._id !== location?.state?.comunity?._id
      );

      // Shuffle the array and pick the first three items
      const shuffled = otherCommunities.sort(() => 0.5 - Math.random());
      let selected = shuffled.slice(0, 3);

      setcommunitiesList((prev) => ({
        ...prev,
        items: selected,
      }));
    }
    window.scrollTo(0, 0);
  }, [location?.state]);

  return (
    <Layout>
      <div className="blog">
        <section
          className="banner-section"
          style={{ backgroundImage: "none", display: "block" }}
        >
          <Helmet>
            <meta charset="utf-8" />
            <title>
              {communityData?.title
                ? `${communityData.title} - RecureMe`
                : "RecureMe - Revolutionizing Wellness: Your Gateway to Superior Telehealth Solutions"}
            </title>
            <meta
              name="description"
              content={
                communityData?.description
                  && `${communityData.description
                      .split(" ")
                      .slice(0, 30)
                      .join(" ")}...`
              }
            />
            <meta
              property="og:title"
              content="RecureMe - Revolutionizing Wellness: Your Gateway to Superior Telehealth Solutions"
            />
            <meta
              property="og:description"
              content={
                communityData?.description
                  && `${communityData.description
                      .split(" ")
                      .slice(0, 30)
                      .join(" ")}...`
              }
            />
          </Helmet>
          <Container style={{ marginBottom: "10rem" }}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div
                  className="banner-content"
                  style={{ marginTop: "10rem", marginBottom: "10rem" }}
                >
                  <div className="main-content-part small-content">
                    {communityData?.community_image && (
                      <img
                        className="banner img-fluid"
                        src={communityData?.community_image}
                        alt="bg-icon"
                      />
                    )}
                    <div
                      className="img-date-part d-flex  align-items-center mt-3"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="prodile-img-name d-flex align-items-center">
                        <img
                          src={
                            communityData?.posted_By?.profile_image
                              ? communityData?.posted_By?.profile_image
                              : DoctorDummy
                          }
                          height={30}
                          width={30}
                          style={{ borderRadius: "50%" }}
                        />
                        <p
                          className="mb-0"
                          style={{ fontWeight: "500", marginLeft: "10px" }}
                        >
                          {communityData?.posted_By?.name}
                        </p>
                      </div>
                      <p
                        className="gray mb-0"
                        style={{
                          fontWeight: "500",
                          marginLeft: "10px",
                          color: "#ADADAD",
                        }}
                      >
                        {" "}
                        {moment(communityData?.created_at).format(
                          "MMM DD YYYY"
                        )}{" "}
                      </p>
                    </div>
                    <div className="titlePart">
                      <h1 className="title">{communityData?.title}</h1>
                      <div className="likeCount">
                        <div className="textPart">
                          <p
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: communityData?.description,
                            }}
                          >
                            {/* {communityData?.description} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="text-start">
                      <h4 className="my-3" style={{ fontWeight: "600" }}>
                        You may also like It!
                      </h4>
                    </div>

                    <Row className="g-4">
                      {communitiesList?.items &&
                        communitiesList?.items?.length === 0 && (
                          <div className="text-center">No Blog Found</div>
                        )}
                      {communitiesList?.items &&
                        communitiesList?.items?.map((item, index) => {
                          return (
                            <>
                              <Col xl="4" md="6" lg="12" key={index}>
                                <div
                                  className="community-card"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/blogDetails", {
                                      state: {
                                        comunity: item,
                                        allCommunities:
                                          location?.state?.allCommunities,
                                      },
                                    });
                                  }}
                                >
                                  <div className="community-card-content">
                                    <div
                                      className="image-part d-flex justify-content-center align-content-center"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/blogDetails", {
                                          state: { comunity: item },
                                        });
                                      }}
                                    >
                                      {item?.community_image && (
                                        <img
                                          src={item?.community_image}
                                          height={170}
                                          width={100}
                                          alt="CommunityImg"
                                        />
                                      )}
                                    </div>
                                    <div className="text-part">
                                      <div
                                        className="img-date-part"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigate("/blogDetails", {
                                            state: { comunity: item },
                                          });
                                        }}
                                      >
                                        <div className="prodile-img-name">
                                          <img
                                            src={
                                              item?.posted_By?.profile_image
                                                ? item?.posted_By?.profile_image
                                                : DoctorDummy
                                            }
                                            height={30}
                                            width={30}
                                            style={{ borderRadius: "50%" }}
                                          />
                                          <p>{item?.posted_By?.name}</p>
                                        </div>
                                        <p className="gray">
                                          {" "}
                                          {moment(item?.created_at).format(
                                            "MMM DD YYYY"
                                          )}{" "}
                                        </p>
                                      </div>
                                      <h6
                                        style={{
                                          cursor: "pointer",
                                          fontWeight: "600",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigate("/blogDetails", {
                                            state: { comunity: item },
                                          });
                                        }}
                                      >
                                        {item?.title}
                                      </h6>
                                      {!item?.community_image && (
                                        <>
                                          <p
                                            style={{
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              width: "100%",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item?.description
                                                  ?.split(/\s+/)
                                                  .slice(0, 50)
                                                  .join(" ") +
                                                (item?.description?.split(/\s+/)
                                                  .length > 50
                                                  ? "..."
                                                  : ""),
                                            }}
                                          />
                                          {/* {item?.description?.split(/\s+/).length > 50 && ( */}
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              color: "#FF6036",
                                              fontWeight: "500",
                                              fontSize: "12px",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/blogDetails", {
                                                state: { comunity: item },
                                              });
                                            }}
                                          >
                                            Read More
                                          </span>
                                          {/* )} */}
                                        </>
                                      )}
                                      <div className="like-comment-data">
                                        <div className="like-comment-text ms-1">
                                          <p className="gray">
                                            {item?.commentCount} Comments
                                          </p>
                                          <p
                                            className="gray ms-3"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {item?.likeCount} Likes
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </>
                          );
                        })}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  );
};

export default CommunityCardDetails;
