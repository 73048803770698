import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Row, Col, Tab, Tabs, Nav, Accordion, Navbar } from 'react-bootstrap';
import headerLogo from './assets/images/header-logo.svg'
import footerLogo from './assets/images/footer-logo.png'
import Layout from './Layout';

function Cookies() { 
const [key, setKey] = useState('home');
const [key2, setKey2] = useState('home');
const [scrolled, setScrolled] = useState(false);
const navigate = useNavigate()
useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
    return(
        <>
         <Layout>
         <main className='main-content-part privacy'>

        <section className='banner-section ' style={{backgroundImage:"none","display":"block"}}>
        <Container>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
        <div className='banner-content'  style={{marginTop:"10rem",marginBottom:"10rem"}}>

                  <h1 className='my-5' style={{fontWeight:"700"}}>Cookies</h1>
                  <h4 style={{fontWeight:"700"}}>Welcome to RecureMe's Cookies Policy.</h4>

<div className='text-muted' style={{lineHeight:"2"}}>
                  <p>
                  This policy provides detailed information about how and why cookies are used on RecureMe's website and mobile applications.</p>


                  <p className='mt-3'>
                  1. What are Cookies?</p>
<span><span className="fw-bold">Definition: </span> Cookies are small text files placed on your device when you visit a website. They help in improving your user experience and in providing tailored services.</span><br />


                    <p className='mt-3'>
                  2. How We Use Cookies</p>
<span><span className="fw-bold">Types of Cookies: </span>We use various types of cookies, including necessary cookies for website functionality, performance cookies for analytics, functional cookies for personalization, and marketing cookies for advertising.</span><br />
<span><span className="fw-bold">Purposes: </span>Cookies are used for enabling website functionality, analyzing site performance, personalizing content, and delivering relevant advertisements.</span><br />

                               <p className='mt-3'>
                  3. Managing Cookies</p>
<span><span className="fw-bold">Browser Settings:</span> You can manage cookies through your web browser settings, allowing you to block or delete cookies.</span><br />
<span><span className="fw-bold">Impact of Disabling Cookies: </span>Disabling cookies may affect the functionality and features of the website.
</span><br />

                  
<p className='mt-3'>
                  4. Third-Party Cookies</p>
<span><span className="fw-bold">Use by Third Parties: </span> Some cookies on our website are set by third-party service providers for various services like analytics or advertising.</span><br />
<span><span className="fw-bold">Third-Party Policies: </span> For information on how these third parties use cookies, please refer to their respective privacy and cookies policies.</span><br />
                  

<p className='mt-3'>
                  5. Consent</p>
<span><span className="fw-bold">Obtaining Consent:</span>When you visit our website, we seek your consent for the use of cookies, except for strictly necessary cookies.</span><br />
<span><span className="fw-bold">Withdrawing Consent: </span> You can withdraw your consent at any time by changing your browser settings.</span><br />

      <p className='mt-3'>
                  6. Cookies Used on Our Website</p>
<span><span className="fw-bold">List of Cookies: </span>A detailed list of cookies used on our website, including their purpose and duration, is provided below.</span><br />
<p> Necessary Cookies: [List of necessary cookies and their purposes]</p>
<p>Performance Cookies: [List of performance cookies and their purposes]</p>
<p>Functional Cookies: [List of functional cookies and their purposes]</p>
<p>Marketing Cookies: [List of marketing cookies and their purposes]</p>                  
<p className='mt-3'>
                  7. Changes to This Policy</p>
<span><span className="fw-bold">Updates:</span>RecureMe may update this cookies policy from time to time. The latest version will always be available on our website.</span><br />
<span><span className="fw-bold">Notification of Changes: </span>Any significant changes to this policy will be communicated through our website or other means.</span><br />
                  
<p className='mt-3'>
                  8. Contact Information</p>
<span><span className="fw-bold">Queries and Concerns:</span>If you have any questions about our use of cookies, please contact us at [RecureMe's support@recureme.com].</span><br />
 

            <p>Therapy, and outcomes.Utilize our comprehensive platform to your full potential as a physiotherapist. excellence in communication, therapy.</p>
             
             
            <div>
<h1 className='my-4' style={{fontWeight:"700"}}>The Comprehensive Platform for</h1>
<h2 style={{fontSize:"20px" ,fontWeight:"700",color:"#ff4817"}}>The Comprehensive Platform for</h2>
<p>Our comprehensive platform to your full potential as a physiotherapist. excellence in communication,
Therapy, and outcomes.Utilize our comprehensive platform to your full potential as a physiotherapist. excellence in communication, therapy.</p>
<h2 style={{fontSize:"20px",fontWeight:"700",color:"#ff4817"}}>The Comprehensive Platform for</h2>
<p>Therapy, and outcomes.Utilize our comprehensive platform to your full potential as a physiotherapist. Therapy, and outcomes.Utilize our comprehensive platform to your full potential as a physiotherapist. excellence in communication, therapy.
</p></div>
                  </div>
          </div>


       
          </Col>
          </Row>
          </Container>
        </section>
        </main>
        </Layout>
        </>
    )
}
export default Cookies;