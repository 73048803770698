import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Row, Col, Tab, Tabs, Nav, Accordion, Navbar } from 'react-bootstrap';
import headerLogo from './assets/images/header-logo.svg'
import footerLogo from './assets/images/footer-logo.png'
import Layout from './Layout';

function Terms() { 
const [key, setKey] = useState('home');
const [key2, setKey2] = useState('home');
const [scrolled, setScrolled] = useState(false);
const navigate = useNavigate()
useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
useEffect(() => {
  window.scrollTo(0, 0);
}, []); 
    return(
        <>
         <Layout>
         <main className='main-content-part privacy'>
        <section className='banner-section ' style={{backgroundImage:"none","display":"block"}}>
        <Container>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
        <div className='banner-content' style={{marginTop:"10rem",marginBottom:"10rem"}}>

                  <h1 className='my-5' style={{fontWeight:"700"}}>Terms & Condition</h1>
                  <h4 style={{fontWeight:"700"}}>Welcome to the RecureMe Terms of Use.</h4>

<div className='text-muted' style={{lineHeight:"2"}}>
                  <p>
                  These terms outline the rules and regulations for the use of RecureMe's Services, available through our website and mobile applications.

</p>


                  <p className='mt-3'>
                  1. Acceptance of Terms</p>
<span><span className="fw-bold">Agreement to Terms:</span> By using our Services, you agree to be bound by these terms. If you do not agree, do not use our Services.</span><br />
<span><span className="fw-bold">Eligibility: </span>You must be at least 18 years old or of legal age to form a binding contract in your jurisdiction to use our Services.</span><br />


                    <p className='mt-3'>
                  2.  Changes to Terms</p>
<span><span className="fw-bold">Updates to Terms:</span> RecureMe reserves the right to modify these terms at any time. We recommend reviewing these terms periodically.</span><br />
<span><span className="fw-bold">Effective Date: </span>Changes are effective immediately upon posting, and your continued use of the Services signifies your acceptance.</span><br />

                               <p className='mt-3'>
                  3. Access and Use of the Services</p>
<span><span className="fw-bold">Grant of Access:</span>  RecureMe grants you a limited, non-exclusive, non-transferable, revocable license to access and use our Services.</span><br />
<span><span className="fw-bold">Usage Restrictions </span>You agree not to misuse the Services, including but not limited to engaging in illegal activities, infringing intellectual property, or disrupting the Services' operation.
</span><br />

                  
<p className='mt-3'>
                  4. Account Registration and Security</p>
<span><span className="fw-bold">Account Creation:</span> You may need to create an account to access certain features of our Services.</span><br />
<span><span className="fw-bold">Account Security: </span>You are responsible for maintaining the confidentiality of your account information and for all activities under your account.</span><br />
                  

<p className='mt-3'>
                  5. Intellectual Property Rights</p>
<span><span className="fw-bold">Ownership:</span> All intellectual property rights in the Services, including content and trademarks, are owned by RecureMe or its licensors.</span><br />
<span><span className="fw-bold">Use of Content: </span> By submitting content, you grant RecureMe a worldwide, perpetual, irrevocable, royalty-free license to use, modify, and distribute your content.</span><br />

      <p className='mt-3'>
                  6. User Content</p>
<span><span className="fw-bold">User Submissions:</span>You may submit content, including feedback, suggestions, and other materials, to the Services.</span><br />
<span><span className="fw-bold">License to User Content: </span> Upon request, we will delete your data in accordance with legal requirements.</span><br />
                  
<p className='mt-3'>
                  7. Disclaimers and Limitation of Liability</p>
<span><span className="fw-bold">No Warranties:</span>The Services are provided "as is" without any warranties, express or implied.</span><br />
<span><span className="fw-bold">Limitation of Liability: </span>RecureMe's liability is limited to the maximum extent permitted by law. We are not liable for indirect, incidental, or consequential damages.</span><br />
                  
<p className='mt-3'>
                  8.  Indemnification</p>
<span><span className="fw-bold">Indemnity:</span>You agree to indemnify and hold harmless RecureMe, its affiliates, officers, and employees from any claims, damages, or expenses arising from your use of the Services.</span><br />

               <p className='mt-3'>
                  9. Termination</p>
<span><span className="fw-bold">Termination Rights:</span>RecureMe may terminate or suspend your access to the Services at any time without notice for breach of these terms.</span><br />
<span><span className="fw-bold">Effect of Termination:</span> Upon termination, your right to use the Services ceases immediately.</span><br />
                     
<p className='mt-3'>
                  10. Governing Law and Jurisdiction</p>
<span><span className="fw-bold">Governing Law:</span>These terms are governed by the laws of India.</span><br />                     
<span><span className="fw-bold">Jurisdiction:</span> Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts of India.</span><br />                     

<p className='mt-3'>
                  11. General Provisions</p>
<span><span className="fw-bold">Entire Agreement:</span> These terms constitute the entire agreement between you and RecureMe regarding the Services.</span><br />                     
<span><span className="fw-bold">Severability:</span> If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will remain in effect.</span><br />                     


       <p className='mt-3'>
                  12. Contact Information</p>
<span><span className="fw-bold">Queries and Concerns:</span>If you have any questions or concerns about these terms, please contact us at [RecureMe's support@recureme.com].</span><br />                     
       

            <p>RecureMe may update these terms periodically.</p>
                  </div>
          </div>
          </Col>
          </Row>
          </Container>
        </section>
        </main>
        </Layout>
        
        </>
    )
}
export default Terms;