import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Row, Col, Tab, Tabs, Nav, Accordion, Navbar } from 'react-bootstrap';
import headerLogo from './assets/images/header-logo.svg'
import footerLogo from './assets/images/footer-logo.png'
import Layout from './Layout';

function Refund() { 
const [key, setKey] = useState('home');
const [key2, setKey2] = useState('home');
const [scrolled, setScrolled] = useState(false);
const navigate = useNavigate()
useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
    return(
        <>
         <Layout>
         <main className='main-content-part privacy'>

        <section className='banner-section ' style={{backgroundImage:"none","display":"block"}}>
        <Container>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
        <div className='banner-content'  style={{marginTop:"10rem",marginBottom:"10rem"}}>

                  <h1 className='my-5' style={{fontWeight:"700"}}>Refund</h1>
                  <h4 style={{fontWeight:"700"}}>Welcome to RecureMe's Refund Policy.</h4>

<div className='text-muted' style={{lineHeight:"2"}}>
                  <p>
                  This policy provides detailed information about how and why cookies are used on RecureMe's website and mobile applications.</p>


                  <p className='mt-3'>
                  1. Payment Data</p>
<span> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Cashfree, RazorPay, PayPal, Payglocal, Juspay, and Stripe.</span><br />


                    <p className='mt-3'>
                  2. Purchase or Fee</p>
<span>You may be required to purchase or pay a fee to access some of our services. You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Website/Mobile Application. We may change prices at any time. All payments shall be in Indian National Rupees.</span><br />

                               <p className='mt-3'>
                  3. Payment Authorization</p>
<span>You agree to pay all charges or fees at the prices then in effect for your purchases, and you authorize us to charge your chosen payment provider for any such amounts upon making your purchase.</span><br />
                  
<p className='mt-3'>
                  4. Refunds and Chargebacks</p>
<span>Any accepted refunds/chargebacks will be routed back to the payer using the same mechanism by which payment was made. Refunds may be delayed based on the payment card provided. We do not offer refunds for partially used periods unless required by law.</span><br />
                  

<p className='mt-3'>
                  5. Accountability Group Subscription</p>
<span>Accountability Group Subscription is automatically renewed at the end of each Subscription Period unless you cancel it at least 24 hours before the expiry of the current Subscription Period.</span><br />

      <p className='mt-3'>
                  6. Termination</p>
<span> RecureMe may terminate your account at any time without notice if it believes that you have violated these terms of use. Upon such termination, you will not be entitled to any refund for purchases.</span><br />
             
<p className='mt-3'>
                  7. Payment Handling</p>
<span>All payment, billing, and transaction processes are handled by the relevant third-party distributor or platform such as Apple's App Store or Google's Google Play. For any payment-related issues, you may contact such distributors directly.</span><br />


<p className='mt-3'>
                  8. International Customers</p>
<span>  The timelines provided for processing of returns in the case of international customers shall vary depending on the mode of purchase etc. Refunds may be delayed based on the payment card provided.                
</span><br />
 

<p className='mt-3'>
                  9. Subscription Plans</p>
<span>RecureMe offers both monthly and annual subscription plans. Prices and specific services are detailed on our subscription page.</span><br />
<p className='mt-3'>
                  10. Billing Cycle</p>
<span>Monthly subscriptions are billed on the same date each month from the date of subscription. Annual subscriptions are billed annually on the date of the initial subscription.</span><br />
<p className='mt-3'>
                  11. Renewals</p>
<span>Subscriptions automatically renew unless canceled at least 24 hours before the renewal date.
 </span><br />
<p className='mt-3'>
                  12. Cancellations</p>
<span>Subscriptions can be canceled anytime via your account settings. Access to services continues until the end of the current paid period.</span><br />
<p className='mt-3'>
                  13. Changes in Prices</p>
<span>Prices are subject to change with prior notice. Current subscribers will receive notice of changes in pricing terms before they take effect.</span><br />
<p className='mt-3 mb-3' >
                  14. Secure Transactions</p>
<span style={{marginBottom:"10rem"}}>All transactions are securely processed. We are committed to protecting your personal and payment information.
</span><br />
<span style={{marginBottom:"10rem"}}>
<br />  <br />  
Subscription Policy <br />  
At Lauruss Infoways Private Limited, operating as recureme.com, we are committed to providing our customers with valuable subscription services. Please review our subscription policy below:<br /><br />  
Subscription Plans:
We offer various subscription plans for our services, each with its own features, duration, and pricing. Details of each plan are available on our website after successfully sign in.<br /><br />
Subscription Activation:
Upon successful payment, your subscription will be activated immediately, and you will gain access to the subscribed services as outlined in the chosen plan.<br /><br />
Subscription Renewal:
Unless explicitly canceled, subscriptions will automatically renew at the end of the subscription period.
Renewal charges will be automatically deducted from the payment method on file unless you choose to cancel your subscription before the renewal date.<br /><br />
Cancellation of Subscription:
You have the option to cancel your subscription at any time. Upon cancellation, your access to the subscribed services will continue until the end of the current billing cycle.
To cancel your subscription, please visit your account settings on recureme.com or contact our customer support team.<br /><br />
Refund Policy:
Refunds are available within 7 days of the initial subscription purchase date.
To be eligible for a refund, the subscription must be canceled within the refund period.
Refunds are only applicable to the unused portion of the subscription period. Any used portion of the subscription will not be refunded.
For more details, please refer to our dedicated Refund Policy.<br /><br />

Refunds for subscription payments will be credited back to the original payment method.

Please note that refunds for subscription payments may take between 7 to 30 days to reflect in your account, depending on the payment gateway and bank processing times.<br /><br />
Subscription Changes:
You may upgrade or downgrade your subscription plan at any time. Any changes to your subscription plan will be prorated based on the remaining duration of your current subscription period.<br /><br />
Communication Preferences:
By subscribing to our services, you consent to receive communications related to your subscription, including renewal notices, service updates, and promotional offers.<br /><br />
Data Privacy:
We are committed to protecting your privacy and personal information. Your subscription data will be handled in accordance with our Privacy Policy.<br /><br />
Policy Updates:
Lauruss Infoways Private Limited reserves the right to update or modify this subscription policy at any time without prior notice. Any changes will be effective immediately upon posting on recureme.com.
By subscribing to our services on recureme.com, you acknowledge that you have read and agree to abide by this subscription policy.
<br /><br />
</span>



<strong>Introducing Recureme's Physiotherapy Subscription Plans</strong>
<br/>
Our subscription plans are tailored to meet the needs of physiotherapists, offering essential tools for patient management and practice growth. Choose from the Basic, Standard, or Advanced Therapy Plan, each designed to streamline workflow and improve patient outcomes. Gain access to a library of exercises, predefined protocols, and collaboration features to enhance your practice.
<br/><br/>

1. *Basic Plan - INR 599/month:* <br/>
   - Web & mobile app access<br/>
   - 1,600+ exercises, 250+ protocols<br/>
   - Assign plans to 5 patients, print/email plans 50 times<br/>
   - Add patients & create exercises<br/><br/>

2. *Standard Plan - INR 1599/month:*<br/>
   - Web & mobile app access<br/>
   - 1,600+ exercises, 250+ protocols<br/>
   - Assign plans to 20 patients, print/email plans 200 times<br/>
   - Add patients & create exercises<br/><br/>

3. *Advanced Therapy Plan - INR 5999/month:*<br/>
   - Web & mobile app access<br/>
   - 1,600+ exercises, 250+ protocols<br/>
   - Assign plans to 100 patients, print/email plans 1000 times<br/>
   - Add patients, create exercises<br/>
   - Up to 10 physiotherapists<br/>


                  </div>
          </div>


       
          </Col>
          </Row>
          </Container>
        </section>
        </main>
        </Layout>
        </>
    )
}
export default Refund;