import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route , Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import Main from './Main';
import Terms from './Terms';
import Privacy from './Privacy';
import Cookies from './Cookies';
import Blogs from './Blogs';
import CommunityCardDetails from './CommunityCardDetails';
import Support from './Support';
import Refund from './Refund';
import Lice from './Lice';
import 'bootstrap-icons/font/bootstrap-icons.css';
function App() {


  return (
    <>
     <BrowserRouter>
      <Routes >
        <Route path='/' element={<Main />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/support' element={<Support />} />
        <Route path='/refund' element={<Refund />} />
        <Route path='/blog' element={<Blogs />} />
        <Route path='/end-user-license-agreement' element={<Lice />} />
        <Route path='/blogDetails' element={<CommunityCardDetails />} />
        {/* <Route path="*" element={<Navigate to="/" />}  /> */}
        </Routes>
        </BrowserRouter>
    </>

  );
}

export default App;
