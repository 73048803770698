import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Row,
  Col,
  Tab,
  Tabs,
  Nav,
  Accordion,
  Navbar,
} from "react-bootstrap";
import headerLogo from "./assets/images/header-logo.svg";
import footerLogo from "./assets/images/footer-logo.png";
import Layout from "./Layout";

function Support() {
  const [key, setKey] = useState("home");
  const [key2, setKey2] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout>
        <main className="main-content-part privacy">
          <section
            className="banner-section "
            style={{ backgroundImage: "none", display: "block" }}
          >
            <Container>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div
                    className="banner-content text-center"
                    style={{ marginTop: "10rem", marginBottom: "10rem" }}
                  >
                    <h1 className="my-5" style={{ fontWeight: "700" }}>
                      Contact us
                    </h1>
                    <br />
                    <div className="d-flex justify-content-between container">
                      <div className="">
                        <h6>Sales</h6>
                        <br />
                        <button
                          className="btn btn-outline-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href =
                              "mailto:support@recureme.com";
                          }}
                        >
                          Contact Sales
                        </button>
                      </div>
                      <div>
                        <h3>General enquiries</h3>
                        <p>
                          For general queries, including partnership
                          opportunities, <br /> please email
                          support@recureme.com
                        </p>
                      </div>

                      <div className="">
                        <h6>Help & Support</h6>
                        <br />
                        <button
                          className="btn btn-outline-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href =
                              "mailto:support@recureme.com";
                          }}
                        >
                          Get Support
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </Layout>
    </>
  );
}
export default Support;
