import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Tab,
  Tabs,
  Nav,
  Accordion,
  Navbar,
} from "react-bootstrap";
import footerLogo from "./assets/images/footer-logo.png";
import headerLogo from "./assets/images/header-logo.svg";
import doctor from "./assets/images/doctor.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
function Layout(props) {
  const [scrolled, setScrolled] = useState(false);
  const [email, setEmail] = useState("");
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setSubscriptionMessage("");
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubscribe = async () => {
    if (!email) {
      setSubscriptionMessage("Please enter an email address.");
      return;
    }
    if (!validateEmail(email)) {
      setSubscriptionMessage("Please enter a valid email address.");
      return;
    }
    try {
      const response = await axios.post("https://api.recureme.com/subscribe", {
        email,
      });
      setSubscriptionMessage(response.data.msg); // 'Successfully subscribed'
      setEmail(""); // Clear the email input field after successful subscription
    } catch (error) {
      setSubscriptionMessage(error.response.data.error || "An error occurred");
    }
  };
  return (
    <>
      <main className="main-content-part">
        {/* header section */}
        <header className={`${scrolled ? "scrolled" : ""} header-part`}>
          <Navbar expand="md">
            <Container>
              <Navbar.Brand
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
              >
                <img src={headerLogo} alt="Logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="">
                  <Nav.Link href="/#product-section">Product</Nav.Link>
                  <Nav.Link href="/#feature-section">Features</Nav.Link>
                  <Nav.Link href="/#benifit-section">Benefits</Nav.Link>
                  <Nav.Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/blog");
                    }}
                  >
                    Blogs
                  </Nav.Link>
                  <Nav.Link
                    href="#feature-section"
                    className="mobile-display"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://patient.recureme.com/login");
                    }}
                  >
                    Patient
                  </Nav.Link>
                  <Nav.Link
                    href="#benifit-section"
                    className="mobile-display"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://physiotherapist.recureme.com/login");
                    }}
                  >
                    Physiotherapist
                  </Nav.Link>
                  <Nav.Link href="#benifit-section" className='mobile-display' onClick={(e) => {
                    e.preventDefault()
                    window.open("https://clinic.recureme.com/login")
                  }}>Clinic</Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <div className="d-flex deskto-display">
                <Button
                  className="me-2 header-outline-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://patient.recureme.com", "_blank");
                  }}
                >
                  Patient
                </Button>{" "}
                <Button
                  className="me-2 header-outline-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://physiotherapist.recureme.com",
                      "_blank"
                    );
                  }}
                >
                  Physiotherapist
                </Button>{" "}
                <Button
                  className="header-outline-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "https://clinic.recureme.com",
                      "_blank"
                    );
                  }}
                >
                  Clinic
                </Button>{" "}
              </div>
            </Container>
          </Navbar>
        </header>

        <div>
          <div className="content">{props.children}</div>
        </div>

        <footer className="footer-section">
          <section className="register-section">
            <Container>
              <div className="box">
                <div className="overlay"></div>
                <Row>
                  <Col xs={8}>
                    <div className="content-part">
                      <h2>
                        Register Now for #1 Innovative Online Physiotherapy
                        Solution: Transforming Your Health Journey!
                      </h2>
                      <Button className="start-btn">Get Started</Button>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="back-img-part"></div>
                  </Col>
                </Row>
              </div>
              <div className="py-5 border-bottom">
              <div className="footer-coppy-part p-0 border-0">
                <div className="email-box d-flex">
                  <h2 style={{ color: "white", width: "50%"}}>
                    Ready to experience our features?
                    It's just matter of one click
                  </h2>
                  <div className="email-part">
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      onKeyDown={(e) => { 
                        if (e.key === "Enter") {
                          handleSubscribe();
                        }
                      }}
                      className="email-input text-white"
                      style={{backgroundColor:"#312e2e",border:"0px"}}
                      placeholder="Write email address"
                    />
                    <Button className="sub-button" onClick={handleSubscribe}>
                      Subscribe
                    </Button>
                  </div>
                </div>
              </div>

              <div className="text-end" style={{color:"#ff4817"}}>
              {subscriptionMessage && <p>{subscriptionMessage}</p>}

              </div>
              </div>
            </Container>
          </section>
          <Container>
            <div>

            </div>


            <footer className="footer">
              <div className="container">
                <div className="row">
                  <div className="footer-col">
                    <a href="#"><img alt="" src={footerLogo} style={{ height: "35px" }} /></a>
                    <p className='mt-4'>Recureme is creating a bridge between physiotherapy and patient</p><br />
                    <div className="social-links">
                      <a href="https://www.instagram.com/recureme_tech/" target="__blank"><i className="bi bi-instagram"></i></a>
                      {/* <a href="#"><i className="bi bi-twitter"></i></a> */}
                      <a target="__blank" href="https://www.facebook.com/profile.php?id=61557263103084"><i className="bi bi-facebook"></i></a>
                      <a target="__blank" href="https://www.linkedin.com/company/recureme-technology-pvt-ltd?trk=public_post_main-feed-card-text"><i className="bi bi-linkedin"></i></a>
                      <a target="__blank" href="https://www.youtube.com/@RecureMe"><i className="bi bi-youtube"></i></a>
                    </div>
                  </div>

                  <div className="footer-col-solution">
                    <h4>Solution</h4>
                    <ul className='p-0'>
                      <li><a target="__blank" href="https://physiotherapist.recureme.com/">Patient</a></li>
                      <li><a target="__blank" href="https://patient.recureme.com/">Physiotherapist</a></li>
                      <li><a target="__blank" href="https://clinic.recureme.com/">Clinic</a></li>
                    </ul>
                  </div>
                  <div className="footer-col-support">
                    <h4>Support</h4>
                    <ul className='p-0'>
                      <li><Link to="/terms">Terms & Conditions</Link></li>
                      <li><Link to="/privacy">Privacy Policy</Link></li>
                      <li><Link to="/refund">Refund</Link></li>
                      <li><Link to="/support">Support</Link></li>
                    </ul>
                  </div>
                  <div className="footer-col">
                    <h4>Contact Details</h4>
                    <ul className='p-0'>
                      <p><i className="bi bi-telephone-fill me-2"></i><b>Canada:</b> +1 437 324 3655 <p className='ms-4'><b>India:</b> +91 90235 00890</p>

                      </p>
                      <p><i className="bi bi-envelope-fill me-2"></i>support@lauruss.com</p>
                      <p><i className="bi bi-geo-alt-fill me-2"></i><b>Canada:</b><p className='ms-4'>Suite 200-V5, 123 Edward St.,
                        Toronto, M5G 1E2,Canada.</p></p>
                      <p className='ms-4'><b>India:</b><p>A1 803, Palladium, Corporate Road, Ahmedabad, Gujarat - 380051</p></p>


                    </ul>
                  </div>
                </div>
              </div><hr />

              <div className='footer-col m-auto'>
                <p className="centered-text">Copyright 2024 @recureme</p>
              </div>
            </footer>

          </Container>
        </footer>
      </main>
    </>
  );
}
export default Layout;
