import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Row,
  Col,
  Tab,
  Tabs,
  Nav,
  Accordion,
  Navbar,
} from "react-bootstrap";
import headerLogo from "./assets/images/header-logo.svg";
import footerLogo from "./assets/images/footer-logo.png";
import Layout from "./Layout";
import axios from "axios";
import moment from "moment";
import DoctorDummy from "./assets/images/defaultdoctor@3x.png";

function Blogs() {
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const [communitiesList, setcommunitiesList] = useState({
    items: [],
    has_more: false,
    totalPages: 0,
  });
  const [page, setPage] = useState(1);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getCommunitiesList();
  }, [page]);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && communitiesList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [communitiesList?.has_more]
  );
  async function getCommunitiesList() {
    setIsLoading(true); // Start loading
    try {
      let url = `https://api.recureme.com/blogList?pageNumber=${page}&myCommunity=true`;
      const data = await axios.get(url);
      if (data?.status === 200) {
        if (page > 1) {
          setcommunitiesList((p) => ({
            ...p,
            items: communitiesList?.items.concat(data?.data?.communities),
          }));
        } else {
          setcommunitiesList((p) => ({ ...p, items: data?.data?.communities }));
        }
        // setcommunitiesList((e) => ({ ...e, items: data?.data?.data }))
        setcommunitiesList((e) => ({
          ...e,
          has_more: data?.data?.has_more,
          totalPages: data?.data?.totalPages,
        }));
      }
    } catch (error) {}
    setIsLoading(false); // Stop loading
  }
  return (
    <>
      <Layout>
        <div className="blog">
          <section
            className="banner-section"
            style={{ backgroundImage: "none", display: "block" }}
          >
            <Container>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div
                    className="banner-content"
                    style={{ marginTop: "10rem", marginBottom: "10rem" }}
                  >
                    <div className="text-center">
                      <h1 className="my-5" style={{ fontWeight: "700" }}>
                        RECENT BLOGS
                      </h1>
                    </div>

                    <Row className="g-4">
                      {isLoading ? (
                        <div className="text-center">Loading...</div>
                      ) : (
                        <>
                          {communitiesList?.items &&
                            communitiesList?.items?.length === 0 && (
                              <div className="text-center">No Blog Found</div>
                            )}
                          {communitiesList?.items &&
                            communitiesList?.items?.map((item, index) => {
                              // Function to get next three communities
                              const getRandomCommunities = (currentIndex) => {
                                let filteredItems =
                                  communitiesList.items.filter(
                                    (_, idx) => idx !== currentIndex
                                  );
                                let randomCommunities = [];
                                for (
                                  let i = 0;
                                  i < 3 && filteredItems.length > 0;
                                  i++
                                ) {
                                  let randomIndex = Math.floor(
                                    Math.random() * filteredItems.length
                                  );
                                  randomCommunities.push(
                                    filteredItems[randomIndex]
                                  );
                                  filteredItems.splice(randomIndex, 1); // Remove the selected item
                                }
                                return randomCommunities;
                              };
                              return (
                                <>
                                  <Col
                                    xl="4"
                                    md="6"
                                    lg="12"
                                    ref={lastBookElementRef}
                                    key={index}
                                  >
                                    <div
                                      className="community-card"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/blogDetails", {
                                          state: {
                                            comunity: item,
                                            nextCommunities:
                                              getRandomCommunities(index),
                                            allCommunities:
                                              communitiesList.items,
                                          },
                                        });
                                      }}
                                    >
                                      <div className="community-card-content">
                                        <div
                                          className="image-part d-flex justify-content-center align-content-center"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/blogDetails", {
                                              state: { comunity: item },
                                            });
                                          }}
                                        >
                                          {item?.community_image && (
                                            <img
                                              src={item?.community_image}
                                              height={170}
                                              width={100}
                                              alt="CommunityImg"
                                            />
                                          )}
                                        </div>
                                        <div className="text-part">
                                          <div
                                            className="img-date-part"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/blogDetails", {
                                                state: { comunity: item },
                                              });
                                            }}
                                          >
                                            <div className="prodile-img-name">
                                              <img
                                                src={
                                                  item?.posted_By?.profile_image
                                                    ? item?.posted_By
                                                        ?.profile_image
                                                    : DoctorDummy
                                                }
                                                height={30}
                                                width={30}
                                                style={{ borderRadius: "50%" }}
                                              />
                                              <p>{item?.posted_By?.name}</p>
                                            </div>
                                            <p className="gray">
                                              {" "}
                                              {moment(item?.created_at).format(
                                                "MMM DD YYYY"
                                              )}{" "}
                                            </p>
                                          </div>
                                          <h6
                                            style={{
                                              cursor: "pointer",
                                              fontWeight: "600",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/blogDetails", {
                                                state: { comunity: item },
                                              });
                                            }}
                                          >
                                            {item?.title}
                                          </h6>
                                          {!item?.community_image && (
                                            <>
                                              <p
                                                style={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  width: "100%",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    item?.description
                                                      ?.split(/\s+/)
                                                      .slice(0, 50)
                                                      .join(" ") +
                                                    (item?.description?.split(
                                                      /\s+/
                                                    ).length > 50
                                                      ? "..."
                                                      : ""),
                                                }}
                                              />
                                              {/* {item?.description?.split(/\s+/).length > 50 && ( */}
                                              <span
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#FF6036",
                                                  fontWeight: "500",
                                                  fontSize: "12px",
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  navigate("/blogDetails", {
                                                    state: { comunity: item },
                                                  });
                                                }}
                                              >
                                                Read More
                                              </span>
                                              {/* )} */}
                                            </>
                                          )}
                                          <div className="like-comment-data">
                                            <div className="like-comment-text ms-1">
                                              <p className="gray">
                                                {item?.commentCount} Comments
                                              </p>
                                              <p
                                                className="gray ms-3"
                                                style={{ cursor: "pointer" }}
                                              >
                                                {item?.likeCount} Likes
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              );
                            })}{" "}
                        </>
                      )}

                      {communitiesList.totalPages > 0 && (
                        <Row>
                          <Col className="d-flex justify-content-center">
                            <div className="pagination">
                              {Array.from(
                                { length: communitiesList.totalPages },
                                (_, i) => i + 1
                              ).map((pageNumber) => (
                                <button
                                  key={pageNumber}
                                  className={`page-item ${
                                    page === pageNumber ? "active" : ""
                                  }`}
                                  onClick={() => setPage(pageNumber)}
                                >
                                  {pageNumber}
                                </button>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </>
  );
}
export default Blogs;
